<template>
  <div>
    <v-row dense class="pt-2">
      <v-col cols="12" md="4">
        <v-autocomplete
          class="c-input-small"
          v-model="input.id_e_market"
          :items="eMarketOptions"
          :label="$t('labels.e_market')"
          :placeholder="$t('labels.e_market')"
          dense
          outlined
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4">
        <v-autocomplete
          class="c-input-small"
          v-model="input.id_old_system"
          :items="oldSystemOptions"
          :label="$t('labels.old_system')"
          :placeholder="$t('labels.old_system')"
          dense
          outlined
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4">
        <v-btn color="success" block @click="generateData">Xuất file</v-btn>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" md="4">
        <v-file-input
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          v-model="input.file_update_e_market"
          @change="onInputFileEMarket"
          :label="$t('labels.file_update_e_market')"
          :placeholder="$t('labels.file_update_e_market')"
          dense
          outlined
          hide-details
          class="c-input-small"
          append-icon="mdi-paperclip"
          prepend-icon=""
        ></v-file-input>
      </v-col>
      <v-col cols="12" md="4">
        <v-file-input
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          v-model="input.file_product"
          @change="onInputFileProduct"
          :label="$t('labels.file_product')"
          :placeholder="$t('labels.file_product')"
          dense
          outlined
          hide-details
          class="c-input-small"
          append-icon="mdi-paperclip"
          prepend-icon=""
        ></v-file-input>
      </v-col>
      <v-col cols="12" md="4">
        <v-file-input
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet "
          v-model="input.file_sync_e_market"
          @change="onInputFileSync"
          :label="$t('labels.file_sync_e_market')"
          :placeholder="$t('labels.file_sync_e_market')"
          dense
          outlined
          hide-details
          class="c-input-small"
          append-icon="mdi-paperclip"
          prepend-icon=""
        ></v-file-input>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <div class="font-weight-medium">
          {{ $t("labels.file_update_e_market") }}
        </div>
        <div v-if="input.id_e_market === 'shopee'">
          <div class="primary--text">Shopee:</div>
          <ol>
            <li>Trang banhang</li>
            <li>Trang Tất cả sản phẩm</li>
            <li>Công cụ xử lý hàng loạt</li>
            <li>Cập nhật hàng loạt</li>
            <li>Thông tin bán hàng</li>
            <li>Tải về</li>
          </ol>
        </div>

        <div v-if="input.id_e_market === 'tiktok'">
          <div class="primary--text">Tiktok:</div>
          <ol>
            <li>Trang Seller</li>
            <li>Sản phẩm</li>
            <li>Công cụ hàng loạt</li>
            <li>Chỉnh sửa hàng loạt sản phẩm</li>
            <li>Chọn các sản phẩm</li>
            <li>Lựa chọn đã được lọc</li>
            <li>Thông tin bán hàng</li>
            <li>Tạo mẫu</li>
            <li>Tải xuống</li>
            <li>Copy dữ liệu ra Excel mới</li>
          </ol>
        </div>

        <div v-if="input.id_e_market === 'lazada'">
          <div class="primary--text">Lazada:</div>
          <ol>
            <li>Trang Seller</li>
            <li>Sản phẩm</li>
            <li>Quản lý sản phẩm</li>
            <li>Quản lý số lượng lớn</li>
            <li>Chỉnh sửa hàng loạt</li>
            <li>Số lượng và Giá bán</li>
            <li>Xuất file excel</li>
            <li>Tạo mẫu</li>
            <li>Tải xuống</li>
          </ol>
        </div>
      </v-col>

      <v-col cols="12" md="4">
        <div class="font-weight-medium">{{ $t("labels.file_product") }}</div>
        <div v-if="input.id_old_system === 'nhanh'">
          <div class="primary--text">Nhanh:</div>
          <ol>
            <li>Trang quản trị</li>
            <li>Sản phẩm</li>
            <li>Sản phẩm</li>
            <li>Thao tác</li>
            <li>
              Xuất excel
              <ul>
                <li>Chọn dữ liệu: Tất cả các trang</li>
                <li>Chọn cột: Tất cả</li>
              </ul>
            </li>
            <li>Xuất dữ liệu</li>
          </ol>
        </div>
      </v-col>

      <v-col cols="12" md="4">
        <div class="font-weight-medium">
          {{ $t("labels.file_sync_e_market") }}
        </div>
        <div v-if="input.id_old_system === 'nhanh'">
          <div class="primary--text">Nhanh:</div>
          <ol>
            <li>Trang quản trị</li>
            <li>Sản phẩm</li>
            <li>Sàn TMĐT → Chọn sàn</li>
            <li>Thao tác</li>
            <li>Xuất excel</li>
          </ol>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { read, write, utils } from "xlsx";
import { saveAs } from "file-saver";
import moment from "moment";
export default {
  name: "SyncItemEMarket",
  data: () => ({
    input: {
      id_e_market: null,
      id_old_system: null,
      id_shop: null,
      id_customer: null,
    },
    eMarketOptions: [
      {
        text: "Shopee",
        value: "shopee",
      },
      {
        text: "Tiktok",
        value: "tiktok",
      },
      {
        text: "Lazada",
        value: "lazada",
      },
    ],
    oldSystemOptions: [
      {
        text: "Nhanh",
        value: "nhanh",
      },
    ],
    shops: [],
    customers: [],
    dataFileEMarket: [],
    dataFileProduct: [],
    dataFileSync: [],
  }),
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    async onInputFileEMarket(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const values = utils.sheet_to_json(worksheet, { header: 1 });
          this.dataFileEMarket = [...values];
        };
        await reader.readAsArrayBuffer(file);
      }
    },
    async onInputFileProduct(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const values = utils.sheet_to_json(worksheet);
          this.dataFileProduct = [...values];
        };
        await reader.readAsArrayBuffer(file);
      }
    },
    async onInputFileSync(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const values = utils.sheet_to_json(worksheet);
          this.dataFileSync = [...values];
        };
        await reader.readAsArrayBuffer(file);
      }
    },

    s2ab(s) {
      let buf = new ArrayBuffer(s.length);
      let view = new Uint8Array(buf);
      for (let i = 0; i != s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },

    generateDataShopeeWNhanh() {
      const codeToOldSystem = {};
      const nameToOldSystem = {};
      for (let i = 0; i < this.dataFileSync.length; i++) {
        const item = this.dataFileSync[i];
        const idShopee =
          item["ID sản phẩm con(Shopee)"] || item["ID sản phẩm cha"];
        const oldSytemCode = item["Mã sản phẩm (Nhanh)"];
        codeToOldSystem[idShopee] = oldSytemCode;

        const oldSystemName = item["Tên sản phẩm (Nhanh)"];
        if (oldSystemName) {
          nameToOldSystem[idShopee] = oldSystemName;
        }
      }

      const oldSystemToSellerSku = {};
      const oldNameSystemToSellerSku = {};
      for (let i = 0; i < this.dataFileProduct.length; i++) {
        const item = this.dataFileProduct[i];
        const oldSytemCode = item["Mã sản phẩm"] || item["Mã vạch"];
        const sellerSku =
          item["Loại sản phẩm"] === "Combo"
            ? item["Mã sản phẩm"] || item["Mã vạch"]
            : item["Mã vạch"];
        oldSystemToSellerSku[oldSytemCode] = sellerSku;

        const oldSystemName = item["Tên sản phẩm"];
        if (oldSystemName) {
          oldNameSystemToSellerSku[oldSystemName] = sellerSku;
        }
      }

      let data = [];
      let nfData = [];
      for (let i = 0; i < 6; i++) {
        const item = this.dataFileEMarket[i];
        data.push(item);
        nfData.push(item);
      }
      for (let i = 6; i < this.dataFileEMarket.length; i++) {
        const item = this.dataFileEMarket[i];
        const code = codeToOldSystem[item[2]] || codeToOldSystem[item[0]];
        if (code) {
          const sellerSku = oldSystemToSellerSku[code];
          if (sellerSku) {
            item[5] = sellerSku;
            data.push(item);
          }
        } else {
          const name = nameToOldSystem[item[2]] || nameToOldSystem[item[0]];
          const sellerSku = oldNameSystemToSellerSku[name];
          if (sellerSku) {
            item[5] = sellerSku;
            data.push(item);
          } else {
            nfData.push(item);
          }
        }
      }
      return { data, nfData };
    },

    generateDataTiktokWNhanh() {
      const codeToOldSystem = {};
      const nameToOldSystem = {};
      for (let i = 0; i < this.dataFileSync.length; i++) {
        const item = this.dataFileSync[i];
        const idTiktok = item["ID SKU(TikTok)"] || item["ID sản phẩm"];
        const oldSytemCode = item["Mã sản phẩm (Nhanh)"];
        codeToOldSystem[idTiktok] = oldSytemCode;

        const oldSystemName = item["Tên sản phẩm (Nhanh)"];
        if (oldSystemName) {
          nameToOldSystem[idTiktok] = oldSystemName;
        }
      }

      const oldSystemToSellerSku = {};
      const oldNameSystemToSellerSku = {};
      for (let i = 0; i < this.dataFileProduct.length; i++) {
        const item = this.dataFileProduct[i];
        const oldSytemCode = item["Mã sản phẩm"] || item["Mã vạch"];
        const sellerSku =
          item["Loại sản phẩm"] === "Combo"
            ? item["Mã sản phẩm"] || item["Mã vạch"]
            : item["Mã vạch"];
        oldSystemToSellerSku[oldSytemCode] = sellerSku;

        const oldSystemName = item["Tên sản phẩm"];
        if (oldSystemName) {
          oldNameSystemToSellerSku[oldSystemName] = sellerSku;
        }
      }

      let data = [];
      let nfData = [];
      for (let i = 0; i < 5; i++) {
        const item = this.dataFileEMarket[i];
        data.push(item);
        nfData.push(item);
      }
      for (let i = 5; i < this.dataFileEMarket.length; i++) {
        const item = this.dataFileEMarket[i];
        const code = codeToOldSystem[item[3]] || codeToOldSystem[item[0]];
        if (code) {
          const sellerSku = oldSystemToSellerSku[code];
          if (sellerSku) {
            item[7] = sellerSku;
            data.push(item);
          }
        } else {
          const name = nameToOldSystem[item[3]] || nameToOldSystem[item[0]];
          const sellerSku = oldNameSystemToSellerSku[name];
          if (sellerSku) {
            item[7] = sellerSku;
            data.push(item);
          } else {
            nfData.push(item);
          }
        }
      }
      return { data, nfData };
    },

    generateDataLazadaWNhanh() {
      const codeToOldSystem = {};
      const nameToOldSystem = {};
      for (let i = 0; i < this.dataFileSync.length; i++) {
        const item = this.dataFileSync[i];
        const skuLazada = item["SKU ID (Lazada)"];
        if (skuLazada) {
          const oldSytemCode = item["Mã sản phẩm (Nhanh)"];
          codeToOldSystem[skuLazada] = oldSytemCode;
        }

        const oldSystemName = item["Tên sản phẩm (Nhanh)"];
        if (oldSystemName) {
          nameToOldSystem[skuLazada] = oldSystemName;
        }
      }

      const oldSystemToSellerSku = {};
      const oldNameSystemToSellerSku = {};
      for (let i = 0; i < this.dataFileProduct.length; i++) {
        const item = this.dataFileProduct[i];
        const oldSytemCode = item["Mã sản phẩm"] || item["Mã vạch"];
        const sellerSku =
          item["Loại sản phẩm"] === "Combo"
            ? item["Mã sản phẩm"] || item["Mã vạch"]
            : item["Mã vạch"];
        oldSystemToSellerSku[oldSytemCode] = sellerSku;

        const oldSystemName = item["Tên sản phẩm"];
        if (oldSystemName) {
          oldNameSystemToSellerSku[oldSystemName] = sellerSku;
        }
      }

      let data = [];
      let nfData = [];
      for (let i = 0; i < 4; i++) {
        const item = this.dataFileEMarket[i];
        data.push(item);
        nfData.push(item);
      }
      for (let i = 4; i < this.dataFileEMarket.length; i++) {
        const item = this.dataFileEMarket[i];
        const code = codeToOldSystem[item[4]] || codeToOldSystem[item[11]];
        if (code) {
          const sellerSku = oldSystemToSellerSku[code];
          if (sellerSku) {
            item[11] = sellerSku;
            data.push(item);
          }
        } else {
          const name = nameToOldSystem[item[4]] || nameToOldSystem[item[11]];
          const sellerSku = oldNameSystemToSellerSku[name];
          if (sellerSku) {
            item[11] = sellerSku;
            data.push(item);
          } else {
            nfData.push(item);
          }
        }
      }
      return { data, nfData };
    },

    generateData() {
      let data = [];
      let nfData = [];
      if (this.input.id_e_market === "shopee") {
        const res = this.generateDataShopeeWNhanh();
        data = res.data;
        nfData = res.nfData;
      } else if (this.input.id_e_market === "tiktok") {
        const res = this.generateDataTiktokWNhanh();
        data = res.data;
        nfData = res.nfData;
      } else if (this.input.id_e_market === "lazada") {
        const res = this.generateDataLazadaWNhanh();
        data = res.data;
        nfData = res.nfData;
      }

      let wscols = [];
      let ws = utils.aoa_to_sheet(data);
      ws["!cols"] = wscols;
      let wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Sheet1");

      if (nfData) {
        let wscb = utils.aoa_to_sheet(nfData);
        wscb["!cols"] = wscols;
        utils.book_append_sheet(wb, wscb, "NotFound");
      }

      const wbout = write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "binary",
      });

      saveAs(
        new Blob([this.s2ab(wbout)], {
          type: "text/plain;charset=utf-8",
        }),
        `${this.input.id_e_market}_${
          this.input.id_old_system
        }_${moment().format("YYYYMMDDHHmm")}.xlsx`
      );
    },
  },
};
</script>

<style scoped></style>
